import appConfig from '@betablocks/shared/lib/config/appConfig'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { appWithTranslation } from 'next-i18next'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import NextNProgress from 'nextjs-progressbar'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '../../styles/globals.css'
import StorefrontProvider from '../components/widgets/StorefrontProvider'
import UserProvider from '../components/widgets/UserProvider'
import { Toast } from '../utils/toast'

const stripePromise = loadStripe(appConfig.stripePublishableKey as string, {
  apiVersion: appConfig.stripeApiVersion as string,
})

function MyApp({ Component, pageProps }: AppProps): JSX.Element {
  const router = useRouter()
  const { t } = useTranslation('common')
  const { verify, verified } = router.query

  useEffect(() => {
    if (verify) {
      Toast.danger(t('toast.verify'))
    } else if (verified) {
      Toast.success(t('toast.verified'))
    }
  }, [router, t, verify, verified])

  return (
    <Elements stripe={stripePromise}>
      <UserProvider user={pageProps.me}>
        <StorefrontProvider storefront={pageProps.storefront ?? null}>
          <ToastContainer hideProgressBar position="top-center" bodyClassName="break-words" />
          <NextNProgress color="#3f81db" />
          <Component {...pageProps} />
        </StorefrontProvider>
      </UserProvider>
    </Elements>
  )
}

export default appWithTranslation(MyApp)
