import { APIStorefront } from '@betablocks/shared/lib/api/storefronts'
import React from 'react'

export const StorefrontContext = React.createContext<{ storefront: APIStorefront | null }>({
  storefront: null,
})

type Props = React.PropsWithChildren<{
  storefront: APIStorefront | null
}>

const StorefrontProvider: React.FC<Props> = ({ children, storefront }) => {
  return <StorefrontContext.Provider value={{ storefront }}>{children}</StorefrontContext.Provider>
}

export default StorefrontProvider
